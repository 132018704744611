import styles from './callout.module.css'

export type SuccessMessage = {
    success: string;
}

export type ErrorMessage = {
    error: string;
}

type MessageProps = {
    message: SuccessMessage | ErrorMessage;
}


export const Callout: React.FC<MessageProps> = ({ message }): JSX.Element => {
    if ('success' in message) {
        return (
            <div>
                <div>
                    <aside className={`${styles.callout} ${styles.success}`}>
                        <b>Access Code:</b>

                        <p><b>{message.success}</b></p>
                    </aside>
                </div>
                <br />
                <div>
                    <aside className={`${styles.callout} ${styles.success}`}>
                        <div><b>Next Steps</b></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                <a href={`https://apps.apple.com/us/app/workplace-from-meta/id944921229?itsct=apps_box_badge&amp;itscg=30200`} style={{ overflow: "hidden", borderRadius: "13px" }}>
                                    <img src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1421280000&h=d58a51e3779b0befd3db8ad146ca6944`} alt={"Download on the App Store"} style={{ borderRadius: "8px", width: "100px", height: "100px" }} />
                                </a>
                            </div>
                            <div style={{ width: "100px", display: "flex", marginTop: "2.1rem", marginLeft: "2rem" }}>
                                <a href={`https://play.google.com/store/apps/details?id=com.facebook.work&hl=en_CA&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`} style={{ overflow: "hidden", borderRadius: "" }}>
                                    <img alt={"Google Play Store badge EN"} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/512px-Google_Play_Store_badge_EN.svg.png"} style={{ borderRadius: "8px", height: "33px" }} />
                                </a>
                            </div>
                        </div>
                        <ol>
                            <li>Click on the <b>App Store</b> to download <b>Workplace</b> app for iPhone or <b>Google Play</b> to download the app if you are on Android.</li>
                            <li>If you are on desktop, click <b><a href={`https://graduatehotels.workplace.com/`}>here</a></b> to go to Graduate's Workplace.</li>
                            <li>Click on <b>Create account with your access code or Sign up with Access Code</b> and enter the above access code.</li>
                            <li>Create <b>username</b> and <b>password</b> to create credentials to log in to <b>Graduate's Workplace.</b></li>
                        </ol>
                    </aside>
                </div>
            </div>
        )
    }

    return (
        <div>
            <aside className={`${styles.callout} ${styles.warning}`}>
                <b> Error:</b>

                <p>{message.error}</p>
            </aside>


        </div>
    )
}