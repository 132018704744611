import { ClaimForm } from './components/ClaimForm';
import { Layout } from './components/Layout';
import './components/global.css'
import './components/Callout'
import { useState } from 'react'
import { Callout, SuccessMessage, ErrorMessage } from './components/Callout';

function App() {
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState("")
  const [display, setDisplay] = useState(false)

  const successMessage: SuccessMessage = {
    success: message
  }

  const errorMessage: ErrorMessage = {
    error: message
  }

  return (
    <Layout>
      <ClaimForm setSuccess={setSuccess} setMessage={setMessage} setDisplay={setDisplay} />
      <br />
      {display ? success ? <Callout message={successMessage} /> : <Callout message={errorMessage} /> : null}
    </Layout>
  );
}

export default App;
